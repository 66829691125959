import { useEffect, useState } from "react";

import { useMsal } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-browser";
import LogRocket from "logrocket";

export const useUser = (): AccountInfo | null => {
  const { accounts } = useMsal();
  const user = accounts[0] || null;

  useEffect(() => {
    if (user) LogRocket.identify(user.username, {});
  }, [user]);

  return user;
};
