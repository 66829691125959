import React, { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "./App.css";
import "antd/dist/reset.css";
import { routes } from "./routes";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnReconnect: false
    }
  }
});

export const msalInstance = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_AD_CLIENT_ID || "",
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AD_TENANT_ID}`,
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false
  }
});

const router = createBrowserRouter(routes);

const RedirectLogin = () => {
  useEffect(() => {
    msalInstance.acquireTokenRedirect({
      account: msalInstance.getActiveAccount() || msalInstance.getAllAccounts()[0] || undefined,
      scopes: []
    });
  }, []);
  return <div style={{ padding: 20 }}>Redirecting to login...</div>;
};

const App = () => (
  <MsalProvider instance={msalInstance}>
    <UnauthenticatedTemplate>
      <RedirectLogin />
    </UnauthenticatedTemplate>
    <AuthenticatedTemplate>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        {window.location.hostname === "localhost" && <ReactQueryDevtools />}
      </QueryClientProvider>
    </AuthenticatedTemplate>
  </MsalProvider>
);
export default App;
