import React, { useState } from "react";
import { Avatar, Popover } from "antd";
import { useUser } from "../../services/auth";
import classNames from "./UserMenu.module.css";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { useMsal } from "@azure/msal-react";

const UserDropdown = ({ logOut }) => (
  <div role="button" tabIndex={0} className={classNames.userMenuMenuItem} onClick={logOut} onKeyDown={logOut}>
    <LogoutOutlined style={{ marginRight: 8 }} />
    Log out
  </div>
);

const UserMenu = () => {
  const [open, setOpen] = useState(false);
  const user = useUser();
  const { instance } = useMsal();

  const logout = () => {
    return instance.logoutRedirect({
      postLogoutRedirectUri: "/"
    });
  };
  if (user) {
    return (
      <Popover
        className={classNames.userMenuPopover}
        trigger="click"
        placement="bottomRight"
        content={<UserDropdown logOut={logout} />}
        open={open}
        onOpenChange={setOpen}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <p style={{ marginBottom: 0, marginRight: 8 }}>{user.name}</p>
          <Avatar icon={<UserOutlined />} size="large" />
        </div>
      </Popover>
    );
  }
  return null;
};

export default UserMenu;
